<template>
  <div class="col-lg-3">
    <side-nav
      :items="navigationItems"
    />
  </div>

  <div class="col-lg-9 mt-lg-0 mt-4">
    <project-item-summary
      v-model:visual-image="fields.visualImage"
      v-model:visual-image-data="fields.visualImageData"
      :created-date-time="fields.createDate"
      :title="fields.title"
    />

    <form @submit.prevent="submit">
      <base-fieldset
        id="basic-info"
        title="Algemeen"
      >
        <basic-info
          v-model="fields"
          :show-update="!isNewItem"
        />  
      </base-fieldset>

      <div 
        v-if="attachmentsModuleIsActive"
        id="attachments"
        class="card mt-4"
      >
        <div 
          class="card-body"
        >
          <file-upload
            v-model:files="files"
            attachment-type="0"
            :entity-id="entityId"
            @update:files="updateFiles"
          />
        </div>
      </div>

      <div
        v-if="photoGalleryModuleIsActive"
        id="photo-gallery"
        class="card mt-4"
      >
        <div
          class="card-body"
        >
          <image-upload
            v-model:images="images"
            attachment-type="0"
            :entity-id="entityId"
            :show-update="!isNewItem"
            @update="updateImages"
          />
        </div>
      </div>

      <div class="card mt-4">
        <div class="card-body">
          <div class="d-flex align-items-center mb-sm-0 mb-4">
            <div class="w-50">
              <h5>Opslaan</h5>
            </div>
            <div class="w-50 text-end">
              <material-button
                :color="submitButtonColor"
                variant="gradient"
                class="float-end mt-4 mb-0 mx-4"
              >
                {{ submitButtonText }}
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isNewItem"
        id="delete"
        class="card mt-4"
      >
        <div class="card-body">
          <div class="d-flex align-items-center mb-sm-0 mb-4">
            <div class="w-50">
              <h5>Verwijder dit bericht</h5>
              <p class="text-sm mb-0">
                Wanneer een bericht verwijderd wordt, kan dit niet meer ongedaan gemaakt worden.
              </p>
            </div>
            <div class="w-50 text-end">
              <material-button
                color="danger"
                variant="gradient"
                class="mb-0 ms-2"
                @click.prevent="remove"
              >
                Delete bericht
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { get as getProject } from '@/api/providers/projects';
import { add, edit, get, remove } from '@/api/providers/projectItems';

import { 
  add as  addFile, 
  get as  getFiles, 
  edit as editFile,
  remove as removeFile 
} from "@/api/providers/attachments/project-item";

import {
  get as getImages,
  add as addImages,
  remove as removeImage
} from '@/api/providers/photo-gallery/project-item';

import BaseFieldset from '@/components/Forms/BaseFieldset.vue';
import BasicInfo from '@/components/Shared/BasicInfo/BasicInfo.vue';
import ProjectItemSummary from '@/components/ProjectOverview/Edit/ProjectItemSummary.vue';

import MaterialButton from '@/components/MaterialButton.vue';
import SideNav from '@/components/UI/SideNav.vue';
import FileUpload from '@/components/UI/FileUpload.vue';
import ImageUpload from '@/components/UI/ImageUpload.vue';

import { createYesNoSweetAlert } from '@/helpers/sweetalert';
import { 
  moduleIsActive,
  getModuleDisplayName
} from '@/helpers/module';
import { setCustomRouteName } from '@/helpers/route-name';
import { CreateItemLabel, EditItemLabel } from '@/helpers/labels'

export default {
  name: 'ProjectItemEdit',

  components: {
    BaseFieldset,
    BasicInfo,
    ProjectItemSummary,
    MaterialButton,
    SideNav,
    FileUpload,
    ImageUpload
  },

  data: () => ({
    fields: {
      title: '',
      description: '',
      publishDate: '',
      organizationId: 0,
      visualImageData: '',
      url: '',
      videoUrl: '',
      projectId: 0
    },
    
    files: [],
    images: [],
    loading: false,
    project: null,

    attachmentsModuleIsActive: false,
    photoGalleryModuleIsActive: false
  }),

  computed: {
    ...mapGetters('account', [
      'organizationId',
    ]),

    isNewItem() {
      const fieldIdMissing = this.fields.id === 0;
      const routeIdMissing = this.$route.params?.projectItemId == null;

      return fieldIdMissing || routeIdMissing;
    },

    navigationItems() {
      return [
        {
          href: '#summary',
          icon: 'image',
          name: 'Samenvatting',
        },
        {
          href: '#basic-info',
          icon: 'notes',
          name: 'Algemeen',
        }
      ]
      .concat(
        this.attachmentsModuleIsActive
          ? {
            href: '#attachments',
            icon: 'folder',
            name: getModuleDisplayName('Attachments')
          }
          : []
      ).concat(
        this.photoGalleryModuleIsActive
          ? {
            href: '#photo-gallery',
            icon: 'image',
            name: 'Media'
          }
          : []
      );
    },

    submitData() {
      return {
        ...this.fields,
        projectId: this.fields.projectId ?? this.$route.params?.projectId,
        projectItemId: this.$route.params?.projectItemId,
        organizationId: this.organizationId,
      };
    },

    submitButtonColor() {
      return this.isNewItem ? 'primary' : 'dark'
    },

    submitButtonText() {
      return this.isNewItem ? 'Opslaan' : 'Update gegevens'
    }
  },

  async mounted() {
    this.photoGalleryModuleIsActive = await moduleIsActive("PhotoGallery");
    this.attachmentsModuleIsActive = await moduleIsActive("Attachments");

    await this.loadProjectItemData();
    this.setRouteDisplayName();

    if (this.isNewItem) {
      this.fields.projectId = this.$route.params.projectId;
      return;
    }

    if (this.photoGalleryModuleIsActive) this.loadProjectItemImages();
    if (this.attachmentsModuleIsActive) this.loadProjectItemAttachments();
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
      addError: 'addError'
    }),

    addProjectItem() {
      add(this.submitData)
        .then(createdEntity => this.updateAttachmentsAndImages(createdEntity.id))
          .then(this.handleSaveSuccess)

        .catch((error) => { if (typeof error.default === 'function') error.default() })
        .then(() => {
          this.loading = false;
        });
    },

    editProjectItem() {
      edit(this.submitData)
       .then(this.updateAttachmentsAndImages(this.$route.params?.projectItemId))
        .then(this.handleSaveSuccess)
        .catch((error) => { if (typeof error.default === 'function') error.default() })
        .then(() => {
          this.loading = false;
        });
    },

    handleGetSuccess(data) {
      Object.entries(data).forEach(([key, value]) => {
        this.fields[key] = value;
      });
    },

    handleRemoveSuccess() {
      this.addNotification({
        description: `${this.fields.title} is verwijderd.`,
        icon: {
          component: 'hive',
        },
      });

      this.navigateToProjectItems();
    },

    handleSaveSuccess() {
      this.addNotification({
        description: `${this.fields.title} is opgeslagen.`,
        icon: {
          component: 'hive',
        },
      });

      this.navigateToProjectItems();
    },

    async loadProjectItemData() {
      if (this.$route.params?.projectItemId == null) return; 

      try {
        let projectItem = await get(this.$route.params.projectItemId);
        this.handleGetSuccess(projectItem);
      }
      catch(error) {
        this.addNotification({
          title: getModuleDisplayName("Projects"),
          description: 'Item kon niet worden gevonden',
          icon: {
            component: 'hive'
          }
        });
      }
    },

    async setRouteDisplayName() {
      try {
        this.project = await getProject(
          this.isNewItem
            ? this.$route.params.projectId
            : this.fields.projectId
        );
        
        setCustomRouteName(
          this.project.title,
          `Bericht ${ this.isNewItem ?  CreateItemLabel : EditItemLabel }`
        );
      }
      catch {
        return;
      };
    },

    navigateToProjectItems() {
      this.$router.push({
        name: 'project-items',
        params: { projectId: this.fields.projectId }
      });
    },

    remove() {
      createYesNoSweetAlert(
        `Bericht ${this.fields.title} verwijderen?`,
        () => {
          remove(this.$route.params.projectItemId)
            .then(this.handleRemoveSuccess)
            .catch((error) => { if (typeof error.default === 'function') error.default() })
        }
      );
    },

    async editFiles() {
      const files = this.files ?? [];

      files.filter(f => f.isUpdated && !f.isNew).forEach(async file => {
        try {
          await editFile(file.id, file.title);
        }
        catch (error) {
          this.addError(error);
        }
      });
    },

    async uploadNewFiles(projectItemId) {
      const files = this.files ?? [];

      files.filter(f => f.isNew && !f.isDeleted).forEach(async file => {
        try {
          await addFile(projectItemId, file.title, file.data);
        }
        catch (error) {
          throw new Error(error);
        }
      });
    },

    async deleteFiles() {
      const files = this.files ?? [];

      files.filter(f => f.isDeleted && !f.isNew).forEach(async file => {
        try {
          await removeFile(file.id);
        }
        catch (error) {
          throw new Error(error);
        }
      });
    },

    async uploadImages(projectItemId) {
      const images = (this.images ?? []).filter(i => i.isNew && !i.isDeleted);
        
      try {
        await addImages(projectItemId, images.map(i => i.data));
      }
      catch (error) {
        throw new Error(error);
      }
    },

    async deleteImages() {
      const images = (this.images ?? []).filter(i => i.isDeleted && !i.isNew);

      images.forEach(async image => {
        try {
          await removeImage(image.id);
        }
        catch (error) {
          throw new Error(error);
        }        
      });
    },

    async updateAttachmentsAndImages(projectItemId) {
      let promises = [
        this.updateAttachments(projectItemId),
        this.updatePhotoGalleryImages(projectItemId)
      ];

      await Promise.all(promises);
    },

    async updateAttachments(projectItemId) {
      if (!this.attachmentsModuleIsActive) return;

      await this.uploadNewFiles(projectItemId);
      await this.editFiles(projectItemId);
      await this.deleteFiles();
    },

    async updatePhotoGalleryImages(projectItemId) {
      if (!this.photoGalleryModuleIsActive) return;

      await this.uploadImages(projectItemId);
      await this.deleteImages(projectItemId);
    },

    loadProjectItemAttachments() {
      getFiles(this.$route.params?.projectItemId)
        .then(this.handleGetFilesSuccess)
          .catch(() => {});
    },

    loadProjectItemImages() {
      getImages(this.$route.params?.projectItemId)
        .then(response => { this.handleGetImagesSuccess(response) })
        .catch(() => {});
    },

    handleGetFilesSuccess(data) {
      this.files = data;
    },

    handleGetImagesSuccess(data) {
      this.images = data?.photoGalleryImages.map(i => { 
        return {
          src: i.visualImage, 
          id: i.id
        }
      });
    },

    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (this.isNewItem) {
        this.addProjectItem();
      } else {
        this.editProjectItem();
      }
    },
  },
};
</script>
